import { poppins } from "@/styles/font";
import { Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react";

const MartlineInfo = () => {
  return (
    <Flex
      backgroundColor={"martlinegray.400"}
      boxShadow={"0px 2px 4px rgba(0, 0, 0, 0.15)"}
      flexDirection={"column"}
      my={8}
      py={3}
      px={4}
      mx={{ base: 5, lg: 0 }}
      rounded={'md'}
    >
      <Text
        className={poppins.className}
        fontWeight={"semibold"}
        color={"black"}
        fontSize={{ base: "14px", lg: "20px" }}
      >
        Martlines NG - Offline Shopping Aid
      </Text>

      <Text
        className={poppins.className}
        fontWeight={"semibold"}
        color={"black"}
        fontSize={{ base: "14px", lg: "20px" }}
        mt={"24px"}
      >
        Shop From All Markets Across Nigeria
      </Text>

      <Text
        className={poppins.className}
        fontWeight={"normal"}
        color={"black"}
        fontSize={{ base: '12px', lg: "16px" }}
        mt={"12px"}
      >
        Experience the power of Martlines: Discover every market in Nigeria with a single tap!
        Start shopping now for hassle-free, doorstep deliveries of your desired items.
      </Text>

      <Text
        className={poppins.className}
        fontWeight={"semibold"}
        color={"black"}
        fontSize={{ base: "14px", lg: "20px" }}
        mt={"24px"}
      >
        Become A Merchant
      </Text>

      <Text
        className={poppins.className}
        fontWeight={"normal"}
        color={"black"}
        fontSize={{ base: '12px', lg: "16px" }}
        mt={"12px"}
      >
        Connect with 206million+ Nigerians anytime!
        Start your store on Martlines and grow your business beyond borders.
      </Text>

      <Text
        className={poppins.className}
        fontWeight={"semibold"}
        color={"black"}
        fontSize={{ base: "14px", lg: "20px" }}
        mt={"24px"}
      >
        Payment Methods On Martlines
      </Text>

      <UnorderedList
        className={poppins.className}
        fontWeight={"normal"}
        color={"black"}
        fontSize={{ base: '12px', lg: "16px" }}
        mt={'12px'}
        spacing={3}
      >
        <ListItem>Pay through Martlines — secure payments with escrow.</ListItem>
        <ListItem>Pay directly to the vendor</ListItem>
      </UnorderedList>
    </Flex>
  );
};

export default MartlineInfo;
